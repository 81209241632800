<template>
	<div class="ele-body">
		<el-card shadow="never">
			<!-- 搜索表单 -->
			<el-form :model="table.where" class="ele-form-search d-flexspabet mt-20"
				@keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
				<div>
					<el-button @click="add" class="ele-btn-icon addbtn" size="small"
						v-if="permission.includes('sys:charge_list:add')">添加服务费设置
					</el-button>
				</div>
				<div class="d-flex">
					<el-form-item label="省:" label-width="35px" class="w-170">
						<el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)"
							placeholder="请选择省" clearable>
							<el-option v-for="option in provArr" :label="option.name" :value="option.pid"
								:key="option.pid"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="市:" label-width="35px" class="w-150">
						<el-select v-model="table.where.cid" @change="handleChangeCity(table.where.cid),$forceUpdate()"
							placeholder="请选择市" clearable>
							<el-option v-for="option in cityArr" :label="option.name" :value="option.cid"
								:key="option.cid"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="县/区:" label-width="62px" class="w-200">
						<el-select v-model="table.where.aid" @change="$forceUpdate()" placeholder="请选择县/区" clearable>
							<el-option v-for="option in districtArr " :label="option.name" :value="option.aid"
								:key="option.aid"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
							class="ele-btn-icon ml-20">搜索</el-button>
						<el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
					</el-form-item>
				</div>
			</el-form>
			<!--         数据表格-->
			<ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
				highlight-current-row :stripe=true>
				<template slot-scope="{index}">
					<el-table-column type="selection" width="45" align="center" fixed="left" />
					<el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left"
						show-overflow-tooltip />
					<el-table-column prop="set_area" label="分润地区" show-overflow-tooltip min-width="160" />
					<el-table-column prop="rake" label="服务费抽成百分比（%）" show-overflow-tooltip min-width="180" />
					<el-table-column prop="one_rule" label="分润人1及比例" show-overflow-tooltip min-width="140" />
					<el-table-column prop="two_rule" label="分润人2及比例" show-overflow-tooltip min-width="140" />
					<el-table-column prop="type_name" label="分润订单类型" show-overflow-tooltip min-width="120" />
					<el-table-column prop="rule_dates" label="分润有效期" show-overflow-tooltip min-width="320" />
					<el-table-column label="更新时间" show-overflow-tooltip min-width="160">
						<template slot-scope="{row}">{{ row.createtime*1000 | toDateString }}</template>
					</el-table-column>
					<el-table-column prop="status_name" label="状态" show-overflow-tooltip min-width="80" />
					<el-table-column label="操作" width="200px" align="center" :resizable="false" fixed="right">
						<template slot-scope="{row}">
							<el-link slot="reference" @click="charge_start(row,3)" icon="el-icon-error" type="danger" 
								:underline="false" v-if="permission.includes('sys:charge_list:start') && row.status == 2">
								关闭
							</el-link>
							<el-link slot="reference" @click="charge_start(row,2)" icon="el-icon-success" type="primary" 
								:underline="false" v-if="permission.includes('sys:charge_list:start') && (row.status == 3 || row.status == 1)">
								开启
							</el-link>
							<el-link @click="edit(row)" icon="el-icon-view" type="primary" :underline="false"
								v-if="permission.includes('sys:charge_list:edit')">查看详情</el-link>
						</template>
					</el-table-column>
				</template>
			</ele-data-table>
		</el-card>
		<!-- 编辑弹窗 -->
		<!--添加/编辑弹窗 -->
		<el-dialog v-dialogDrag :title="editForm.id?'查看服务费设置':'添加服务费设置'" :visible.sync="showEdit" width="1000px" @closed="editForm={}" :destroy-on-close="true" :lock-scroll="false">
			<el-form :model="editForm" ref="editForm" :rules="editRules" label-width="180px" class="driverForm">
				<el-card shadow="never">
					<div>
						<el-form-item label="请选择分润地区：" prop="pid">
							<el-select v-model="editForm.pid" @change="handleChangeProv(editForm.pid)"
								class="selectStyle mr-10 mb-20" :disabled="editForm.id?true:false"
								placeholder="请选择省" clearable>
								<el-option v-for="option in provArr" :label="option.name" :value="option.pid"
									:key="option.pid"></el-option>
							</el-select>
							<el-select v-model="editForm.cid" @change="handleChangeCity(editForm.cid)"
								class="selectStyle mr-10 mb-20" :disabled="editForm.id?true:false"
								placeholder="请选择市" clearable>
								<el-option v-for="option in cityArr" :label="option.name" :value="option.cid"
									:key="option.cid"></el-option>
							</el-select>
							<el-select v-model="editForm.aid" @change="[$forceUpdate(),getfenRun()]"
								class="selectStyle mr-10 mb-20" :disabled="editForm.id?true:false"
								placeholder="请选择县/区" clearable>
								<el-option v-for="option in districtArr " :label="option.name" :value="option.aid"
									:key="option.aid"></el-option>
							</el-select>
						</el-form-item>
					</div>
					<!--            1、商家服务类-跑腿，2出租车，3拼个车，4商超，5餐饮-->
					<el-form-item label="分润订单类型：" prop="type">
						<el-select v-model="editForm.type" :disabled="editForm.id?true:false" placeholder="请选择分润订单类型" class="input216" clearable>
							<el-option v-for="option in typeList" :value="option.type" :key="option.type" :label="option.name"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="服务费抽成比例：" prop="rake">
						<el-input v-model.number="editForm.rake" controls-position="right" placeholder="请填写服务费抽成比例" clearable class="input216" :disabled="editForm.id?true:false" />
						% / 单<div class='prompt'>（服务费抽成比例包含支付宝和微信第三方支付平台收取的0.6%手续费，此费用由分润双方均摊）</div>
					</el-form-item>
					<el-form-item label="分润人1：">
						<el-input v-model="editForm.one_rule" controls-position="right" placeholder="请选择分润人1" clearable class="input216" disabled/>
					</el-form-item>
					<el-form-item prop="rule_one_proportion">
						<el-input v-model="editForm.rule_one_proportion" controls-position="right"
							placeholder="请填写分润比例" clearable class="input216" :disabled="editForm.id?true:false" /> %
					</el-form-item>
					<el-form-item label="分润人2：" prop="two_rule">
						<el-select v-model="editForm.two_rule" :disabled="editForm.id?true:false" class="input216 mr-20" placeholder="请选择分润人2">
							<el-option v-for="option in fenRunPeoples" :value="option.id" :key="option.id"
								:label="option.real_name"></el-option>
						</el-select>
						<!-- <el-button @click="getfenRun" type="primary">获取分润人数据</el-button> -->
					</el-form-item>
					<el-form-item prop="rule_two_proportion">
						<el-input v-model="editForm.rule_two_proportion" controls-position="right"
							placeholder="请填写分润比例" clearable class="input216" :disabled="editForm.id?true:false" /> %
					</el-form-item>
					<el-form-item label="分润有效时间:" prop="start_date">
						<el-date-picker v-model="editForm.start_date" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss"
							placeholder="开始时间" :disabled="editForm.id?true:false" clearable>
						</el-date-picker> 至
						<el-date-picker v-model="editForm.end_date" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss"
							placeholder="结束时间" :disabled="editForm.id?true:false" clearable>
						</el-date-picker>
					</el-form-item>
				</el-card>
			</el-form>
			<div slot="footer" v-if="!editForm.id">
				<el-button @click="showEdit=false">取消</el-button>
				<el-button type="primary" @click="save">提交</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import {
		mapGetters
	} from "vuex";
	export default {
		name: "SysChargeList",
		data() {
			var checkRake = (rule, value, callback) => {
				if (!Number.isInteger(value)) {
					callback(new Error('请输入数字值'));
				}else{
					if (value>100 || value<0) {
						return callback(new Error('注意：比例不得小于0%且不得大于100%'));
					}
				}
			};
			return {
				table: {
					url: '/range/charge_list',
					where: {}
				}, // 表格配置
				choose: [], // 表格选中数据
				showEdit: false, // 是否显示表单弹窗
				editForm: {
					one_rule: 'admin'
				}, // 表单数据
				editRules: { // 表单验证规则
					pid: [{
						required: true,
						message: '请选择省份',
						trigger: 'change'
					}],
					type: [{
						required: true,
						message: '请选择分润订单类型',
						trigger: 'change'
					}],
					rake: [{
						required: true,
						message: '请填写服务费抽成比例'
					},{
						validator: checkRake, 
						trigger: 'blur',
					}],
					scope: [{
						required: true,
						message: '请选择分润订单类型',
						trigger: 'change'
					}],
					rule_one_proportion: [{
						required: true,
						message: '请填写分润比例',
						trigger: 'blur'
					}],
					two_rule: [{
						required: true,
						message: '请选择分润人',
						trigger: 'change'
					}],
					rule_two_proportion: [{
						required: true,
						message: '请填写分润比例',
						trigger: 'blur'
					}],
					oversion: [{
						required: true,
						message: '请输入苹果版版本号',
						trigger: 'blur'
					}],
					constraint: [{
						required: true,
						message: '请选择分润有效时间',
						trigger: 'change'
					}],
					start_date: [{
						required: true,
						message: '请选择分润有效时间',
						trigger: 'change'
					}],
				},
				provArr: [],
				cityArr: [],
				districtArr: [],
				activeName: 'first',
				activeEdit: 'run-driver',
				fenRunPeoples: [],
				typeList:[
					{
						type:1,
						name:'跑腿'
					},
					{
						type:2,
						name:'巡游出租'
					},
					{
						type:3,
						name:'城际定制'
					},
					{
						type:4,
						name:'商超'
					},
					{
						type:5,
						name:'叫餐'
					},
				]
			}
		},
		created() {
			this.$http.get('/common/province_list').then(res => {
				let data = JSON.parse(res.data)
				this.provArr = data
			})
			// this.getfenRun()
		},
		computed: {
			...mapGetters(["permission"]),
		},
		mounted() {},
		methods: {
			add() {
				this.showEdit = true
				this.editForm.one_rule = 'admin'
			},
			getfenRun() {
				this.$http.post('range/get_admin_list',{pid:this.editForm.pid,cid:this.editForm.cid,aid:this.editForm.aid}).then(res => {
					let data = res.data
					this.fenRunPeoples = data.data
				})
			},
			charge_start(row,status) {
				if (status == 3) {
					this.$confirm('确定关闭当前服务费设置?', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/range/charge_start', {
							id: row.id,
							open_status: 3
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: '关闭成功'
								});
								this.$refs.table.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					})
				} 
				if(status == 2){
					this.$confirm('确定开启当前服务费设置?', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/range/charge_start', {
							id: row.id,
							open_status: 2
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: '开启成功'
								});
								this.$refs.table.reload();
								this.faceStart = '关闭'
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					})
				}
			},
			/**
			 *选择省
			 **/
			handleChangeProv(e) {
				/** 获取被选省份的pid**/
				let pid = ''
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.cityArr = data
					/** 选择省份清空市县**/
					this.table.where.cid = ''
					this.table.where.aid = ''
				})
			},
			/**
			 *选择市
			 **/
			handleChangeCity(e) {
				if(e){
				/** 获取被选市的cid**/
				let cid = ''
				this.cityArr.forEach(function(item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.table.where.aid = ''

				})
				}else{
					this.table.where.pid = ''
					this.table.where.aid = ''
				}
			},
			/**
			 * 显示编辑
			 */
			edit(row) {
				this.$http.get('/range/charge_info?id=' + row.id).then(res => {
					if (res.data.code === 0) {
						res.data.data.pid = res.data.data.pname
						res.data.data.cid = res.data.data.cname
						res.data.data.aid = res.data.data.aname
						res.data.data.one_rule = 'admin'
						this.editForm = res.data.data;
						this.showEdit = true;
					} else {
						this.$message.error(res.data.msg);
					}
				}).catch(e => {
					this.$message.error(e.message);
				});
			},
			/**
			 * 保存编辑
			 */
			save() {
				// this.$refs['editForm'].validate((valid) => {
					if (this.editForm.aid && this.editForm.type && this.editForm.rake && this.editForm.rule_one_proportion 
							&& this.editForm.two_rule && this.editForm.rule_two_proportion 
							&& this.editForm.start_date && this.editForm.end_date) {
						if(this.editForm.start_date > this.editForm.end_date){
							this.$message.error("开始时间不得大于结束时间");
						}else{
							const loading = this.$loading({lock: true});
							this.editForm.one_rule = '1'
							this.$http.post('/range/charge_add', this.editForm).then(res => {
								loading.close();
								if (res.data.code === 0) {
									this.showEdit = false;
									this.$message({
										type: 'success',
										message: res.data.msg
									});
									this.$refs.table.reload();
								} else {
									this.editForm.one_rule = 'admin'
									this.$message.error(res.data.msg);
								}
							}).catch(e => {
								loading.close();
								this.$message.error(e.message);
								this.editForm.one_rule = 'admin'
							});
						}
					} else {
						this.$message.error("请完善设置内容");
					}
				// });
			},
		}
	}
</script>

<style scoped>
	.ele-block .el-upload,
	.el-upload-dragger {
		width: 100%;
	}
	/deep/.el-tabs__nav {
		margin-left: 20px
	}
	.prompt {
		color: #FF0000;
		font-size: 14px;
	}
</style>
